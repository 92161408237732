.User {
  $color-youtube: #e62117;
  $color-twitch: #9146ff;
  $color-blanco: #fff;
  $transition: 0.3s;

  position: relative;
  transition: $transition;

  * {
    transition: $transition;
  }

  .online {
    background-color: #eb0400;
    border-radius: 3px;
    color: $color-blanco;
    display: inline-block;
    font-size: 13px;
    font-weight: bold;
    padding: 0px 5px;
    pointer-events: none;
    position: absolute;
    right: 5px;
    text-align: center;
    top: 5px;
    z-index: 20;

    span {
      animation: blink 3s linear infinite;
    }
  }

  .image-container {
    clip-path: polygon(-1px -1px, 100% -1px, 100% 100%, -1px 85%);
    height: 65%;
    position: relative;
    width: 100%;

    .img {
      height: 100%;
      max-width: 100%;
      object-fit: cover;
      width: 100%;
      z-index: 1;
    }
    .user-texts {
      bottom: 35px;
      position: absolute;
      text-align: center;
      width: 100%;
    }

    &::before {
      bottom: 0;
      content: "";
      height: 60%;
      left: 0;
      position: absolute;
      width: 100%;
      z-index: 2;
    }

    > div {
      z-index: 3;
    }
  }

  .boton-canal {
    align-items: center;
    border-radius: 5px;
    color: $color-blanco;
    display: flex;
    font-size: 16px;
    height: 42px;
    justify-content: center;
    margin-bottom: 10px;
    padding: 0 10px;

    svg {
      margin-left: 10px;
    }
  }

  .redes-sociales {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    justify-content: center;
    margin-bottom: 15px;
    min-height: 22px;
    width: 100%;

    span {
      color: black;
      font-size: 14px;
    }

    .red-social {
      align-items: center;
      display: flex;
      height: 28px;
      justify-content: center;
      width: 28px;
    }
  }

  .red-social {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .stats-container {
    margin-bottom: 15px;

    .stat {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .title {
        font-size: 16px;
        text-transform: uppercase;
      }

      .value {
        font-size: 14px;
        font-weight: bold;
        height: 22px;
        text-align: center;
        width: 100%;
      }
    }
  }

  &.twitch {
    .image-container {
      &::before {
        background-image: linear-gradient(
          180deg,
          transparentize($color-blanco, 1) 0%,
          transparentize($color-twitch, 0.3) 45%,
          $color-twitch 100%
        );
      }
    }

    .red-social {
      color: $color-twitch;

      svg {
        path {
          fill: $color-twitch;
        }
      }
    }

    .stats-container {
      .stat {
        .value {
          color: $color-twitch;
        }
      }
    }

    .boton-canal {
      background-color: $color-twitch;

      &:hover {
        text-decoration: underline;
      }
    }

    &:hover {
      background-color: $color-twitch;

      .boton-canal {
        color: $color-twitch;

        svg {
          path {
            fill: $color-twitch;
          }
        }
      }
    }
  }

  &.youtube {
    .image-container {
      &::before {
        background-image: linear-gradient(
          180deg,
          transparentize($color-blanco, 1) 0%,
          transparentize($color-youtube, 0.3) 45%,
          $color-youtube 100%
        );
      }
    }

    .red-social {
      color: $color-youtube;

      svg {
        path {
          fill: $color-youtube;
        }
      }
    }

    .stats-container {
      .stat {
        .value {
          color: $color-youtube;
        }
      }
    }

    .boton-canal {
      background-color: $color-youtube;
    }

    &:hover {
      background-color: $color-youtube;

      .boton-canal {
        color: $color-youtube;

        svg {
          path {
            fill: $color-youtube;
          }
        }
      }
    }
  }

  &:hover {
    .redes-sociales {
      span {
        color: $color-blanco;
      }

      svg {
        path {
          fill: $color-blanco;
        }
      }
    }

    .image-container {
      img {
        transform: scale(1.2) rotate(10deg);
      }
    }

    .boton-canal {
      background-color: $color-blanco;
    }

    .stats-container {
      .stat {
        .value,
        .title {
          color: $color-blanco;
        }
      }
    }
  }

  @keyframes blink {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
}
