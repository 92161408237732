.LoaderSmall {
  $color-youtube: #e62117;
  $color-twitch: #9146ff;

  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;

  .lds-ellipsis {
    align-items: center;
    display: flex;
    height: 100%;
    position: relative;
    width: 64px;
  }
  .lds-ellipsis div {
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
    background: rgb(99, 1, 255);
    border-radius: 50%;
    height: 11px;
    position: absolute;
    top: calc(50% - 11px / 2);
    width: 11px;
  }
  .lds-ellipsis div:nth-child(1) {
    animation: lds-ellipsis1 0.6s infinite;
    left: 6px;
  }
  .lds-ellipsis div:nth-child(2) {
    animation: lds-ellipsis2 0.6s infinite;
    left: 6px;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 26px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 45px;
    animation: lds-ellipsis3 0.6s infinite;
  }

  &.twitch {
    .lds-ellipsis div {
      background-color: $color-twitch;
    }
  }

  &.youtube {
    .lds-ellipsis div {
      background-color: $color-youtube;
    }
  }

  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(19px, 0);
    }
  }
}
